<template>
    <div class="list pb-nav">
        <SubHeader :title="title" link="/mypage"/>

        <ul class="basic_list d-flex flex-wrap flex-column p-3 last-border-0" v-if="notice_list&&notice_list.length>0">
            <li v-for="(item,index) in notice_list" :key="index" class="d-flex justify-content-between mb-3 border-bottom border-gray-relative-200 pb-3">
                <router-link :to="`/cs/notice/${item.code}`" class="left d-flex w-100">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-truncate fw-500">{{ item.title }}</span>
                        <small class="text-gray-relative-500"><span class="small">{{ item.date }}</span></small>
                    </div>
                </router-link>
            </li>
        </ul>
        
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                목록이 없습니다.
            </p>
        </div>
    </div>
</template>

<script>

import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title:'공지사항',
            notice_list: []
        }
    },
    computed:{
    },
    methods:{
        GetNoticeList(){
            this.$http.post('/front/cs/GetNoticeList', {}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  
                            
                            this.notice_list = body.list
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )

        }
    },
    mounted(){
        this.GetNoticeList()
    },
}
</script>
<style lang="scss" scoped>
</style>