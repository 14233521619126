<template>
    <div class="pb-5">
        <SubHeader :title="title" :link="'/mypage'"/>

        <div class="search_input px-3 position-relative mb-3">
            <div class="position-relative">
                <input class="form-control thick text-body bg-body border-gray-relative-300" type="text" placeholder="질문 검색" v-model="search" @keyup="GetFaqList">
                <div class="position-absolute end-0 top-50 translate-middle-y">
                    <div class="px-3 small" v-if="search==''"><i class="far fa-search text-gray-500"></i></div>
                    <div class="px-3 small" v-else @click="search=''"><i class="fas fa-times-circle text-gray-500"></i></div>
                </div>
            </div>
        </div>
        <div class="mx-3">
            <ul class="board_list last-border-0" v-if="faq_list&&faq_list.length>0">
                <li v-for="(item,index) in faq_list" :key="index" class="border-bottom border-gray-relative-200">
                    <div class="txt_box d-flex w-100 align-items-center py-3 pe-2" @click="open_faq_idx == item.no ? open_faq_idx=-1 : open_faq_idx = item.no">
                        <b class="me-2 pe-1">Q.</b>
                        <p class="tit ">{{item.title}}</p>
                        <i class="fal small ps-3 ms-auto text-gray-relative-500" :class="{'fa-chevron-down':open_faq_idx != item.no, 'fa-chevron-up':open_faq_idx == item.no}"></i>
                    </div>
                    <div class="txt_box small px-3 py-3 bg-gray-relative-100 border-top border-gray-relative-200" v-if="open_faq_idx == item.no" v-html="item.memo"></div>
                </li>
            </ul>  
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    준비중입니다.
                </p>
            </div>  
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title: '자주 찾는 질문',
            faq_list: [],
            search: '',
            open_faq_idx: -1,
        }
    },
    mounted(){
        this.GetFaqList();
    },
    methods: {
        searchFn(){
            this.GetFaqList();  
        },
        GetFaqList(){
            const search = this.search;
            const body = {search};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/cs/GetFaqList', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  
                            
                            this.faq_list = body.list
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        }
    },
}
</script>
