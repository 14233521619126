<template>
    <div class="notice list pb-nav">
        <SubHeader :title="title" />
        <div class="d-flex flex-column py-3 px-3 mb-3">
            <h6 class="mb-1 fw-500">{{notice_title}}</h6>
            <small class="mb-4 text-gray-relative-500"><span class="small">{{notice_date}}</span></small>
            <div class="row mb-4" v-if="notice_image !=''">
                <div class="mx-auto">
                    <img :src="notice_image" class="col-12"/>
                </div>
            </div>
            <div class="small desc" v-html="notice_memo"></div>
            
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title:'공지사항',
            code : this.$route.params.code,
            notice_date : '',
            notice_title : '',
            notice_memo : '',
            notice_image  : '',
        }
    },
    methods:{
        GetNoticeDetail(){
            const code = this.code;
            const body = {code}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/cs/GetNoticeDetail', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  
                            
                            this.notice_title = body.info.title;
                            this.notice_date = body.info.date;
                            this.notice_memo = body.info.memo;
                            this.notice_image = body.info.image;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )

        }
    },
    mounted(){
        this.GetNoticeDetail();
    },
}
</script>
<style lang="scss">
.notice{
    .desc{
        img{
            display: block;
            width: 100%;
            max-width: 600px;
        }
    }
}
</style>